import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from './Layout';
import Navbar from './components/Navbar';
import { useAuth } from './AuthContext';
import HotelOccupancyChart from './components/HotelOccupancyChart';
import HotelOccupancyAvgRateChart from './components/HotelOccupancyAvgRateChart';
import HotelOccupancyPercentageChart from './components/HotelOccupancyPercentageChart';
import HotelOccupancyOccupiedRoomsChart from './components/HotelOccupancyOccupiedRoomsChart';
import LeadTimeStatsChart from './components/LeadTimeStatsChart';

const HotelData = () => {
  const [occupancyData, setOccupancyData] = useState([]);
  const [occupancyDataAllRooms, setOccupancyDataAllRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [leadTimeStats, setLeadTimeStats] = useState([]);  
  const [error, setError] = useState(null);
  const { isAuthenticated, token } = useAuth();
  const [selectedRoomType, setSelectedRoomType] = useState('AllRooms');

  useEffect(() => {
    const fetchOccupancyData = async () => {
      try {
        console.log('Fetching occupancy data...');
        const [response, responseAllRooms, leadTimeResponse] = await Promise.all([
          axios.get('/api/occupancy-data'),
          axios.get('/api/occupancy-data-all-rooms'),
          axios.get('/api/lead-time-stats', {
            headers: { Authorization: `Bearer ${token}` }
          })             
        ]);

        console.log('Occupancy Data Response:', response);
        console.log('Occupancy Data All Rooms Response:', responseAllRooms);

        if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
          throw new Error('Invalid or empty occupancy data received for all room types');
        }

        if (!responseAllRooms.data || !Array.isArray(responseAllRooms.data) || responseAllRooms.data.length === 0) {
          throw new Error('Invalid or empty occupancy data received for all rooms');
        }

        setOccupancyData(response.data);
        setOccupancyDataAllRooms(responseAllRooms.data);
        setLeadTimeStats(leadTimeResponse.data);        
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching occupancy data:', error);
        console.error('Error details:', error.response ? error.response.data : 'No response data');
        setError(error.message || 'An error occurred while fetching data');
        setIsLoading(false);
      }
    };

    fetchOccupancyData();
  }, []);

  const handleRoomTypeChange = (roomType) => {
    setSelectedRoomType(roomType);
  };

  if (isLoading) {
    return (
      <Layout>
        <Navbar />
        <h1 className="">Mantra Epping - Hotel Data</h1>        
        <div className="mt-8 flex flex-col gap-4 w-52">
          <div className="skeleton h-32 w-full"></div>
          <div className="skeleton h-4 w-28"></div>
          <div className="skeleton h-4 w-full"></div>
          <div className="skeleton h-4 w-full"></div>
        </div> 
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Navbar />
        <div className="alert alert-error mt-8">Error: {error}</div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Navbar />      
      <h1 className="">Mantra Epping - Hotel Data</h1>
      {occupancyData.length > 0 ? (
        <div className="mt-8">
          <h2 className=""><button className="btn">Average Nightly Rate (ex GST) &amp; Occupancy</button></h2> 
          <HotelOccupancyChart 
            occupancyData={occupancyData} 
            selectedRoomType={selectedRoomType}
            onRoomTypeChange={setSelectedRoomType}
          />
        </div>
      ) : (
        <div className="alert alert-warning mt-4">No occupancy data available</div>
      )}
      <div className="divider"></div>
      {occupancyData.length > 0 ? (
        <>
          <div className="mt-8">
            <h2 className=""><button className="btn">Average Nightly Rate (ex GST) by room type</button></h2>
            <HotelOccupancyAvgRateChart occupancyData={occupancyData} />
          </div>
          <div className="divider"></div>
          <div className="mt-8">
            <h2 className=""><button className="btn">Occupancy Percentage by room type</button></h2>
            <HotelOccupancyPercentageChart occupancyData={occupancyData} />
          </div>
          <div className="divider"></div>
          <div className="mt-8">
            <h2 className=""><button className="btn">Occupied Room Count by room type</button></h2>
            <HotelOccupancyOccupiedRoomsChart occupancyData={occupancyData} />
          </div>
        </>
      ) : (
        <div className="alert alert-warning mt-4">No occupancy data available</div>
      )}

      {leadTimeStats.length > 0 && (
        <div className="mt-8">
          <h2 className="">
          <button className="btn">Booking Lead Time vs ADR Statistics (Last 12 Months)</button></h2>
          <LeadTimeStatsChart stats={leadTimeStats} />
        </div>
      )}
    </Layout>
  );
};

export default HotelData;