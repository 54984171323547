import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import ChartDateRange from './ChartDateRange';

const HotelOccupancyChart = ({ occupancyData, selectedRoomType, onRoomTypeChange }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const startOfMonth = DateTime.now().startOf('month').toISODate();
  const today = DateTime.now().toISODate();
  const thirtyDaysAgo = DateTime.now().minus({ days: 30 }).toISODate();

  const [selectedDateRange, setSelectedDateRange] = useState({
    start: thirtyDaysAgo,
    end: today
  });

  const handleDateRangeChange = (event) => {
    const { name, value } = event.target;
    setSelectedDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // First, filter by room type
    let filteredData;
    if (selectedRoomType === 'AllRooms') {
      filteredData = occupancyData.filter(data => data.RoomType === 'AllRooms');
    } else {
      filteredData = occupancyData.filter(data => data.RoomType === selectedRoomType);
    }

    // Then, apply date range filter
    filteredData = filteredData.filter(data => {
      const dataDate = DateTime.fromISO(data.Calendar_Date);
      return dataDate >= DateTime.fromISO(selectedDateRange.start) && 
             dataDate <= DateTime.fromISO(selectedDateRange.end);
    });

    const dateDataMap = filteredData.reduce((map, data) => {
      const date = DateTime.fromISO(data.Calendar_Date).toFormat('yyyy-MM-dd');
      if (!map[date]) {
        map[date] = {};
      }
      map[date].avgRate = data.AvgNightlyRateOverZero;
      map[date].occupancy = data.PercentageOccupied;
      return map;
    }, {});

    const labels = Object.keys(dateDataMap).sort();
    const avgRateData = labels.map(date => dateDataMap[date].avgRate);
    const occupancyPercentageData = labels.map(date => dateDataMap[date].occupancy);

    const getBackgroundColor = (context) => {
      const index = context.dataIndex;
      const date = DateTime.fromISO(labels[index]);
      const dayOfWeek = date.weekday;
      return dayOfWeek === 6 || dayOfWeek === 7 ? 'rgba(96, 193, 97, 0.6)' : 'rgba(54, 162, 235, 0.6)';
    };

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Average Nightly Rate',
            data: avgRateData,
            backgroundColor: getBackgroundColor,
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            yAxisID: 'y',
          },
          {
            label: 'Occupancy Percentage',
            data: occupancyPercentageData,
            type: 'line',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            fill: false,
            yAxisID: 'y1',
          },
          {
            label: '50% Occupancy',
            data: labels.map(() => 50),
            type: 'line',
            borderColor: 'rgba(255, 0, 0, 0.5)',
            borderWidth: 2,
            borderDash: [5, 5],
            fill: false,
            pointRadius: 0,
            yAxisID: 'y1',
            order: 3
          },          
        ],
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'EEE MMM d',
              },
              tooltipFormat: 'EEE MMM d, yyyy',
            },
          },
          y: {
            beginAtZero: true,
            position: 'left',
            title: {
              display: true,
              text: 'Average Nightly Rate',
            },
          },
          y1: {
            beginAtZero: true,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: 'Occupancy Percentage',
            },
            min: 0,
            max: 100,            
          },
        },
        elements: {
          line: {
            tension: 0.4,
          },
        },
      },
    });
  }, [occupancyData, selectedRoomType, selectedDateRange]);

  const roomTypes = ['AllRooms', ...new Set(occupancyData.map(data => data.RoomType).filter(type => type !== 'AllRooms'))];

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <ChartDateRange
          selectedDateRange={selectedDateRange}
          handleDateRangeChange={handleDateRangeChange}
        />
        <select
          className="select select-bordered w-full max-w-xs"
          value={selectedRoomType}
          onChange={(e) => onRoomTypeChange(e.target.value)}
        >
          {roomTypes.map((roomType) => (
            <option key={roomType} value={roomType}>
              {roomType === 'AllRooms' ? 'Combined Rooms' : roomType}
            </option>
          ))}
        </select>
      </div>
      <canvas ref={chartRef} />
    </>
  );
};

export default HotelOccupancyChart;